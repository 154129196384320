import React from "react";
import { h4Medium, h6Regular } from "../../../shared/constants";
import CartItem from "../../cart/cart-item/cart-item.component";
import { formatDeliveryDate } from "./Parcel.helper";
import { getDateInfo } from "../../../shared/dates";

interface ParcelProps {
  number: number;
  availableDelivery: { from: string; to: string };
  parcelLines: any[];
  isoDate: string;
  startTime: string;
  endTime: string;
  isMoreThanOneParcel: boolean;
  children?: React.ReactNode;
  hourPicked: any;
  fromTo: string;
}

const Parcel: React.FC<ParcelProps> = ({
  number,
  children,
  parcelLines,
  isoDate,
  startTime,
  endTime,
  isMoreThanOneParcel,
  hourPicked,
  fromTo
}) => {
  const { customFormattedDate } = getDateInfo(isoDate);

  return (
    <div style={{ marginTop: 40 }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 12,
          paddingBottom: 32,
        }}
      >
        <span style={{ ...(h4Medium as any) }}>Arriving {customFormattedDate}{fromTo ? ',' : ''} {fromTo}</span>
      </div>
      {children}
      <div
        style={{
          borderTop: "1px dashed #15222F1A",
          marginTop: children ? 32 : 0,
        }}
      >
        {parcelLines?.map((item) => (
          <CartItem
            key={item.id}
            item={item}
            onCartItemClicked={(item: any) => { }}
          />
        ))}
      </div>
    </div>
  );
};

export default Parcel;

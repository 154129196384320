import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useQuery } from "@apollo/client";
import { Redirect, useHistory, useLocation } from "react-router-dom";

import { GET_PRODUCT } from "../../queries/queries";
import { GET_DELIVERY_DATE } from "../../queries/delivery-date.queries";
import ItemPageBreadcrumbs from "./item-page-breadcrumbs/item-page-breadcrumbs.jsx";
import { useLazyQuery } from "@apollo/client";

import "./item-page.css";

import ItemPageImageGalery from "./item-page-image-galery/item-page-image-galery.component";
import ItemPageMerchantAndTitle from "./item-page-merchant-and-title/item-page-merchant-and-title.component";
import ItemPageAddButton from "./item-page-add-button/item-page-add-button.component";
import ItemPageItemDetails from "./item-page-item-details/item-page-item-details.component";
import ItemPageResponsive from "./item-page-responsive/item-page-responsive.component";
import { ItemPageAlsoLike } from "./item-page-also-like/item-page-also-like.component";
import Loader from "../common/loader/loader.component";
import { useTranslation } from "react-i18next";
import { h6Semibold } from "../../shared/constants";
import { getDateInfo } from "../../shared/dates";
import useLocationFromSession from "../../shared/hooks/useLocationFromSession";

function Item() {
  const history = useHistory();
  const location = useLocation();
  const locationPath = location?.pathname?.split("/");
  const productSlug = locationPath[locationPath.length - 1];
  const { t } = useTranslation();
  const { queryLocation, isLocationValid } = useLocationFromSession();

  const { loading, error, data } = useQuery(GET_PRODUCT, {
    variables: { slug: productSlug },
  });

  const [getDeliveryDate,
    { loading: deliverydateLoading, error: deliveryDateError, data: deliveryDateData }] = useLazyQuery(GET_DELIVERY_DATE);

  const fetchDeliveryDate = () => {
    getDeliveryDate({
      variables: { productVariantId: currentVariant?.id, location: queryLocation },
    });
  };

  const [currentVariant, setCurrentVariant] = useState(null);
  const [variantsArray, setVariantsArray] = useState([]);
  const [thumbnailsList, setThumbnailsList] = useState([]);
  const [mainImage, setMainImage] = useState(null);

  useEffect(() => {
    // Extract variant from URL search query
    const searchParams = new URLSearchParams(location.search);
    const variantFromQuery = searchParams.get("variant");

    // Find matching variant from variantsArray
    const foundVariant = variantsArray.find(
      (variant) => variant.name === variantFromQuery
    );

    if (foundVariant) {
      setCurrentVariant(foundVariant);
    }
    if (!variantFromQuery && variantsArray?.length) {
      const variant = data?.product?.variants[0];
      handleVariantSelect(variant);
    }
  }, [location, variantsArray]);

  useEffect(() => {
    if (!data) return;

    setVariantsArray(data.product?.variants);

    if (data.product?.variants?.length === 1) {
      setInitialImages(data);
      setCurrentVariant(data.product?.variants[0]);
    } else {
      const variant = data.product?.variants[0];
      setCurrentImages(variant);
    }
  }, [data]);

  useEffect(() => {
    if (!currentVariant || !currentVariant?.id || !isLocationValid) return;

    fetchDeliveryDate();
  }, [currentVariant])

  if (!productSlug) return <Redirect to="/catalog" />;

  if (error) return `Error! ${error.message}`;

  if (data?.product === null) return <Redirect to="/catalog" />;

  const setInitialImages = (data) => {
    let assets = [];
    data?.product?.variants?.map((variant) =>
      variant?.assets.map((asset) => assets.push(asset))
    );
    manageImages(data?.product?.assets[0], [
      ...data.product?.assets,
      ...assets,
    ]);
  };

  const setCurrentImages = (variant) => {
    manageImages(variant?.assets[0], variant?.assets);
    setCurrentVariant(variant);
  };

  const manageImages = (mainImage, thumbnailsList) => {
    setMainImage(mainImage);
    setThumbnailsList(thumbnailsList);
  };

  const collection = data?.product?.collections?.find(
    (collection) => collection?.children?.length
  );
  const facet = data?.product?.facetValues[0];
  const itemName = data?.product?.name;

  // Function to handle variant selection and update URL
  const handleVariantSelect = (variant) => {
    /*
    Temp solution till the variant params will be solved and fixed
    */
    manageImages(variant?.assets[0], variant?.assets);
    setCurrentVariant(variant);

    // const searchParams = new URLSearchParams(location.search);
    // searchParams.set("variant", variant);
    // history.push({ search: searchParams.toString() });
  };

  let date = null;

  if (deliveryDateData && deliveryDateData?.
    deliveryDate?.deliveryDelayed) {
    const deliveryDate = deliveryDateData?.deliveryDate?.deliveryDate;
    if (deliveryDate) {
      date = getDateInfo(deliveryDate);
    }
  }

  return (
    <React.Fragment>
      <ItemPageBreadcrumbs
        collection={collection}
        facet={facet}
        itemName={itemName}
      />
      <ItemPageResponsive
        data={data}
        currentVariant={currentVariant}
        mainImage={mainImage}
        thumbnailsList={thumbnailsList}
        variantsArray={variantsArray}
        setCurrentImages={setCurrentImages}
      />

      {loading ? (
        <Loader color={"#B73232"} size={50} />
      ) : (
        <div className="item">
          <ItemPageImageGalery
            mainImage={mainImage}
            thumbnailsList={thumbnailsList}
          />
          <div className="item__details">
            <div className="item__details__info">
              <ItemPageMerchantAndTitle data={data} />

              {variantsArray?.length > 1 ? (
                <div
                  style={{
                    position: "relative",
                    marginTop: 16,
                    marginBottom: 8,
                  }}
                >
                  <span className={clsx("h5-medium", "item__variants-title")}>
                    {t("itemPage.options")}:
                  </span>
                  <div className="item__variants">
                    {variantsArray.map((variant) => (
                      <div
                        key={variant.id}
                        onClick={() => handleVariantSelect(variant)}
                        className={clsx({
                          "item__variants-variant": true,
                          selected: variant?.id === currentVariant?.id,
                        })}
                      >
                        <span className="item__variants-variant-img">
                          <img
                            alt={variant?.featuredAsset?.name}
                            src={variant?.featuredAsset?.preview}
                          />
                        </span>
                        <div className="item__variants-variant-info">
                          <span className="item__variants-variant-name">
                            {variant?.name}
                          </span>
                          <span className="item__variants-variant-price">
                            ₪{(variant?.priceWithTax / 100).toFixed(2)}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="item__details__info__actions-container">
                <div className="item__details__info__actions">
                  <span className="item__details__info__actions--price h4-medium">
                    ₪ {(currentVariant?.priceWithTax / 100)?.toFixed(2)}
                  </span>
                  <ItemPageAddButton
                    item={currentVariant}
                    preview={data?.product?.assets[0]?.source}
                  />
                </div>
                {deliveryDateData?.
                  deliveryDate?.deliveryDelayed ? <div>
                  <span className="h6-regular">
                    {date?.daysDifference > 1 ? t("itemPage.nextDeliveryInXDays", {
                      number: date?.daysDifference,
                    }) : t("itemPage.nextDeliveryInXDay", {
                      number: date?.daysDifference,
                    })}
                  </span>
                  <span style={{ ...h6Semibold, opacity: 0.8 }}>
                    {date?.formattedDate}
                  </span>
                </div> : null}
              </div>
            </div>
            <ItemPageItemDetails data={data} />
            <ItemPageAlsoLike data={data} />
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default Item;

import React from "react";
import { useTranslation } from "react-i18next";
import CartItem from "../../cart/cart-item/cart-item.component";
import { flex, h4Medium, h6Regular } from "../../../shared/constants";
import { ReactComponent as SadIcon } from "../../../assets/sad.svg";
import { ReactComponent as EllipseIcon } from "../../../assets/ellipse.svg";

interface TagProps {
    // text: string;
    // color: string;
    // backgroundColor: string;
    // onClick?: () => void;
    undeliverableItems: any[]; // TODO a type should be added
}

const UnavailableDeliveryItems: React.FC<TagProps> = ({ undeliverableItems }) => {
    const { t } = useTranslation();
    return (<>
        <div style={{ ...flex, flexDirection: 'column', gap: 8 }}>
            <div style={{ ...flex, gap: 16 }}>
                <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <EllipseIcon />
                    <SadIcon style={{ position: 'absolute' }} />
                </div>
                <span style={{ ...h4Medium as any }}>{t('checkout.unavailableItems')}</span>
            </div>
            <span style={{ ...h6Regular as any }}>{t('checkout.unavailableItemsSub')}</span>
        </div>
        <div
            style={{
                marginTop: 32,
            }}
        >
            {undeliverableItems?.map((item) => (
                <CartItem
                    key={item.id}
                    item={item}
                    onCartItemClicked={(item: any) => { }}
                    shouldDisplayActions={false}
                />
            ))}
        </div>
    </>
    );
};

export default UnavailableDeliveryItems;

export const h1 = {
  fontSize: 73,
  lineHeight: "100px",
  color: "#15222f",
};

export const h2 = {
  fontFamily: "DM Serif Display",
  fontWeight: "bold",
  fontSize: 60,
  lineHeight: "80px",
  color: "#15222F",
};

export const h3 = {
  fontFamily: "DM Serif Display",
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: 29,
  lineHeight: "40px",
  color: "#15222F",
};

export const h7Regular = {
  fontFamily: ["Barlow", "Assistant"],
  fontWeight: "normal",
  fontSize: 13,
  lineHeight: "16px",
  color: "#15222F",
};

export const h7Medium = {
  fontFamily: ["Barlow", "Assistant"],
  fontWeight: 500,
  fontSize: 13,
  lineHeight: "16px",
};

export const h6Semibold = {
  fontFamily: ["Barlow", "Assistant"],
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: 17,
  lineHeight: "20.4px",
};

export const h6Medium = {
  fontFamily: ["Barlow", "Assistant"],
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: 17,
  lineHeight: "20.4px",
};

export const h6Regular = {
  fontFamily: ["Barlow", "Assistant"],
  fontWeight: "normal",
  fontSize: 17,
  lineHeight: "20px",
  color: "#15222F",
};

export const h5Regular = {
  fontFamily: ["Barlow", "Assistant"],
  fontWeight: "normal",
  fontSize: 19,
  lineHeight: "24px",
  color: "#15222F",
};

export const h5Semibold = {
  fontFamily: ["Barlow", "Assistant"],
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: 19,
  lineHeight: "24px",
};

export const h5Medium = {
  fontFamily: ["Barlow", "Assistant"],
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: 19,
  lineHeight: "24px",
  direction: "ltr !impotant",
};

export const h5Light = {
  fontFamily: ["Barlow", "Assistant"],
  fontStyle: "normal",
  fontWeight: 300,
  fontSize: 19,
  lineHeight: "24px",
};

export const h4Medium = {
  fontFamily: ["Barlow", "Assistant"],
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: 25,
  lineHeight: "32px",
  color: "#15222F",
};

export const h4Light = {
  fontFamily: ["Barlow", "Assistant"],
  fontStyle: "normal",
  fontWeight: 300,
  fontSize: 25,
  lineHeight: "32px",
  color: "#15222F",
};

export const flex = {
  display: "flex"
}

export const textAlignCenter = {
  textAlign: "center",
};

export const pointer = {
  cursor: "pointer",
};

export const monthNames = [
  "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

export const shortMonthNames = [
  "Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

export const getShortMonthAndDay = (timestamp) => {
  // Create a new Date object from the timestamp
  const date = new Date(timestamp);

  // Get the month (months are zero-based, so January is 0)
  const monthIndex = date.getMonth();

  // Get the short version of the month name using the month index
  const shortMonthName = shortMonthNames[monthIndex];

  // Get the day of the month
  const day = date.getDate();

  return day && shortMonthName ? `${shortMonthName} ${day}` : null
};

import React from "react";
import { makeStyles } from "@material-ui/core";
import { h6Medium } from "../../../shared/constants";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  container: (props) => ({
    display: "flex",
    width: "100%",
    padding: "16px 0px 16px 0px",
    borderBottom: `1px dashed #15222F1A`,
    justifyContent: "space-between",
    alignItems: "center",
    background: `${props.deliveryDate === null ? 'pink' : 'transparent'}`
  }),

  description: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    alignContent: "center",
    width: "50%",
    gap: 10,
  },

  imageContainer: {
    width: 32,
    height: 32,
    borderRadius: 8,
  },

  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: 8,
  },

  name: {
    fontFamily: "Barlow",
    fontSize: "13px",
    fontWeight: 400,
    lineHeight: "16px",
    opacity: 0.4,
  },

  qty: {
    fontFamily: "Barlow",
    fontSize: "13px",
    fontWeight: 400,
    lineHeight: "16px",
    opacity: 0.64,
    marginRight: 24,
  },
});

const CheckoutTotalItem = ({ item }) => {
  const { t } = useTranslation();

  const preview = item?.productVariant?.featuredAsset ? item?.productVariant?.featuredAsset?.preview : item?.featuredAsset?.preview;
  const name = item?.productVariant?.featuredAsset ? item?.productVariant?.featuredAsset?.name : item?.featuredAsset?.name;
  const { deliveryDate } = item || {};
  const classes = useStyles({ deliveryDate })

  if (!deliveryDate) {
    return null;
  }

  return (
    <div className={classes.container}>
      <div className={classes.description}>
        <div
          className={classes.imageContainer}
          style={{ marginRight: t("css.side") === "ltr" ? 24 : 0 }}
        >
          <img
            src={preview}
            alt={name}
            className={classes.image}
          />
        </div>
        <span className={classes.name}>{item?.productVariant?.name}</span>
      </div>

      <div style={{ direction: "ltr" }}>
        <span className={classes.qty}>{item?.quantity}</span>
        <span style={{ ...h6Medium, opacity: 0.72 }}>
          ₪ {((item?.unitPriceWithTax * item?.quantity) / 100).toFixed(2)}
        </span>
      </div>
    </div>
  );
};

export default CheckoutTotalItem;

import moment from 'moment';

moment.locale('en', {
  calendar: {
    sameDay: '[Today,] MMM D',
    nextDay: '[Tomorrow,] MMM D',
    nextWeek: 'ddd, MMM D',
    sameElse: 'ddd, MMM D',
  },
});

export const getDateInfo = (dateString: string) => {
  // Parse the date as UTC
  const deliveryDate = moment.utc(dateString).startOf('day');
  const today = moment.utc().startOf('day');

  // Calculate the difference in calendar days from today
  const daysDifference = deliveryDate.diff(today, 'days');

  // Format the date in "MMM D, YYYY" format
  const formattedDate = deliveryDate.format('MMM D, YYYY');

  // Generate the calendar-based delivery info
  const deliveryInfo = deliveryDate.calendar(); // Uses the calendar format based on current date

  // Handle singular/plural days for the delivery in X days
  const dayText = daysDifference === 1 ? 'day' : 'days';

  let deliveryDetail = '';
  if (daysDifference > 0) {
    deliveryDetail = `delivery in ${Math.abs(daysDifference)} ${dayText} (${deliveryDate.format('ddd')} ${deliveryDate.format('D')})`;
  }

  // Custom format for "Today, Jan 19", "Tomorrow, Jan 20", etc.
  let customFormattedDate = '';
  if (daysDifference === 0) {
    customFormattedDate = `Today, ${deliveryDate.format('MMM D')}`;
  } else if (daysDifference === 1) {
    customFormattedDate = `Tomorrow, ${deliveryDate.format('MMM D')}`;
  } else {
    customFormattedDate = `${deliveryDate.format('dddd')}, ${deliveryDate.format('MMM D')}`;
  }


  return {
    daysDifference,
    formattedDate,
    deliveryInfo,
    deliveryDetail, // Add the custom "delivery in X days" format
    customFormattedDate
  };
};
